$primaryColor: #B9B9B9;
$secondColor: #E5E5E5;
.simple-white {
  .play-pause-container {
    height: 17px;
    width: 116px;
    background: linear-gradient(to bottom, rgba(229,229,229,1) 0%,rgba(185,185,185,1) 100%);
    padding: 5px;
    border-radius: 5px;
    clear: both;
    .play, .pause {
      width: 14px;
      height: 16px;
      display: inline-block;
      overflow: hidden;
      text-indent: -9999px;
      transition-property: background;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      cursor: pointer;
      float: left;
    }
    .play {
      background-image: url("/images/simple/white/play.png");
      &:hover {
        background: url("/images/simple/white/play_hover.png");
      }

      &:active {
        background: url("/images/simple/white/play_active.png");
      }
    }
    .pause {
      background: url("/images/simple/white/pause.png");
      &:hover {
        background: url("/images/simple/white/pause_hover.png");
      }

      &:active {
        background: url("/images/simple/white/pause_active.png");
      }
    }
    .mutecontrol {
      position: absolute;
      top: 8px;
      left: 26px;
      width: 7px;
      height: 11px;
      cursor: pointer;
      background: url("/images/simple/white/muteactive.png");
      &.active {
        background: url("/images/simple/white/sound.png");
      }
    }
    .volume {
      //margin-top: 10px;
      width: 80px;
      position: absolute;
      right:5px;
      top: 10px;
    }
    .noUi-horizontal {
      height: 6px;
      background: $primaryColor;
      box-shadow: none;
    }
    .noUi-connect {
      box-shadow: none;
      background: $secondColor;
    }
    .noUi-horizontal .noUi-handle {
      width: 8px;
      height: 8px;
      left: -3px;
      top: -2px;
      cursor: pointer;
      border-radius: 50%;
      &:before, &:after {
        content: none;
      }
    }
    .noUi-target {
      border: 1px solid $secondColor;
    }
  }
}