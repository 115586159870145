.marquee {
  overflow: hidden;
  text-align: left;
}
.marquee.marquee-speed-drowsy:before {
  animation-duration: 30s;
}
.marquee.marquee-speed-slow:before {
  animation-duration: 20s;
}
.marquee.marquee-speed-custom:before {
  animation-duration: inherit;
}
.marquee.marquee-speed-normal:before {
  animation-duration: 10s;
}
.marquee.marquee-speed-fast:before {
  animation-duration: 7s;
}
.marquee.marquee-speed-swift:before {
  animation-duration: 5s;
}
.marquee.marquee-speed-hyper:before {
  animation-duration: 2s;
}
@keyframes movement-smooth {
  from {
    transform: translateX(0%);
    left: 100%;
  }
  to {
    transform: translateX(-100%);
    left: 0%;
  }
}
@keyframes movement-steps20 {
  0% {
    transform: translateX(0%);
    left: 100%;
  }
  5%,
  9.99% {
    transform: translateX(-5%);
    left: 95%;
  }
  10%,
  14.99% {
    transform: translateX(-10%);
    left: 90%;
  }
  15%,
  19.99% {
    transform: translateX(-15%);
    left: 85%;
  }
  20%,
  24.99% {
    transform: translateX(-20%);
    left: 80%;
  }
  25%,
  29.99% {
    transform: translateX(-25%);
    left: 75%;
  }
  30%,
  34.99% {
    transform: translateX(-30%);
    left: 70%;
  }
  35%,
  39.99% {
    transform: translateX(-35%);
    left: 65%;
  }
  40%,
  44.99% {
    transform: translateX(-40%);
    left: 60%;
  }
  45%,
  49.99% {
    transform: translateX(-45%);
    left: 55%;
  }
  50%,
  54.99% {
    transform: translateX(-50%);
    left: 50%;
  }
  55%,
  59.99% {
    transform: translateX(-55%);
    left: 45%;
  }
  60%,
  64.99% {
    transform: translateX(-60%);
    left: 40%;
  }
  65%,
  69.99% {
    transform: translateX(-65%);
    left: 35%;
  }
  70%,
  74.99% {
    transform: translateX(-70%);
    left: 30%;
  }
  75%,
  79.99% {
    transform: translateX(-75%);
    left: 25%;
  }
  80%,
  84.99% {
    transform: translateX(-80%);
    left: 20%;
  }
  85%,
  89.99% {
    transform: translateX(-85%);
    left: 15%;
  }
  90%,
  94.99% {
    transform: translateX(-90%);
    left: 10%;
  }
  95%,
  99.99% {
    transform: translateX(-95%);
    left: 5%;
  }
  100% {
    transform: translateX(-100%);
    left: 0%;
  }
}
@keyframes movement-steps10 {
  0% {
    transform: translateX(0%);
    left: 100%;
  }
  10%,
  19.99% {
    transform: translateX(-10%);
    left: 90%;
  }
  20%,
  29.99% {
    transform: translateX(-20%);
    left: 80%;
  }
  30%,
  39.99% {
    transform: translateX(-30%);
    left: 70%;
  }
  40%,
  49.99% {
    transform: translateX(-40%);
    left: 60%;
  }
  50%,
  59.99% {
    transform: translateX(-50%);
    left: 50%;
  }
  60%,
  69.99% {
    transform: translateX(-60%);
    left: 40%;
  }
  70%,
  79.99% {
    transform: translateX(-70%);
    left: 30%;
  }
  80%,
  89.99% {
    transform: translateX(-80%);
    left: 20%;
  }
  90%,
  99.99% {
    transform: translateX(-90%);
    left: 10%;
  }
  100% {
    transform: translateX(-100%);
    left: 0%;
  }
}
@keyframes movement-steps5 {
  0%,
  19.99% {
    transform: translateX(0%);
    left: 100%;
  }
  20%,
  39.99% {
    transform: translateX(-20%);
    left: 80%;
  }
  40%,
  59.99% {
    transform: translateX(-40%);
    left: 60%;
  }
  60%,
  79.99% {
    transform: translateX(-60%);
    left: 40%;
  }
  80%,
  99.99% {
    transform: translateX(-80%);
    left: 20%;
  }
  100% {
    transform: translateX(-100%);
    left: 0%;
  }
}
.marquee.marquee-movement-smooth:before {
  animation-name: movement-smooth;
}
.marquee.marquee-movement-steps20:before {
  animation-name: movement-steps20;
}
.marquee.marquee-movement-steps10:before {
  animation-name: movement-steps10;
}
.marquee.marquee-movement-steps5:before {
  animation-name: movement-steps5;
}
.marquee.marquee-direction-left:before,
.marquee.marquee-direction-normal:before {
  animation-direction: normal;
}
.marquee.marquee-direction-alternate:before {
  animation-direction: alternate;
}
.marquee.marquee-direction-right:before,
.marquee.marquee-direction-reverse:before {
  animation-direction: reverse;
}
.marquee:before {
  content: attr(data-marquee);
  white-space: nowrap;
  position: relative;
  transform: translateX(-50%);
  display: inline-block;
  left: 50%;
  animation: linear infinite;
}
.marquee:before {
  animation-duration: 10s;
}
.marquee:before {
  animation-name: movement-smooth;
}
.marquee:before {
  animation-direction: normal;
}
