$primaryColor: #81b1ca;
$secondColor: #076596;
.extra-sky {
  .play-pause-container {
    height: 49px;
    width: 149px;
    background: url("/images/extra/sky/bg.png");
    clear: both;
    .play, .pause {
      width: 40px;
      height: 40px;
      display: inline-block;
      overflow: hidden;
      text-indent: -9999px;
      transition-property: background;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      cursor: pointer;
      position: relative;
      top: 5px;
      margin-left: 6px;
    }
    .play {
      background-image: url("/images/extra/sky/play.png");
      &:hover {
        background: url("/images/extra/sky/play_hover.png");
      }

      &:active {
        background: url("/images/extra/sky/play_active.png");
      }
    }
    .pause {
      background: url("/images/extra/sky/pause.png");
      &:hover {
        background: url("/images/extra/sky/pause_hover.png");
      }

      &:active {
        background: url("/images/extra/sky/pause_active.png");
      }
    }
    .mutecontrol {
      position: absolute;
      top: 30px;
      left: 50px;
      width: 7px;
      height: 11px;
      cursor: pointer;
      background: url("/images/extra/sky/muteactive.png");
      &.active {
        background: url("/images/extra/sky/mute.png");
      }
    }
    .volume {
      //margin-top: 10px;
      width: 70px;
      position: absolute;
      right: 15px;
      top: 33px;
    }
    .noUi-horizontal {
      height: 6px;
      background: $primaryColor;
      box-shadow: none;
    }
    .noUi-connect {
      box-shadow: none;
      background: $secondColor;
    }
    .noUi-horizontal .noUi-handle {
      width: 8px;
      height: 8px;
      left: -3px;
      top: -2px;
      cursor: pointer;
      border-radius: 50%;
      &:before, &:after {
        content: none;
      }
    }
    .noUi-target {
      border: 1px solid $secondColor;
    }
    .current-song {
      position: absolute;
      font-size: 12px;
      top: 8px;
      right: 10px;
      width: 87px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}