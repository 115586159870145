$primaryColor: #222222;
$secondColor: #7D7D7D;
.simple-black {
  .play-pause-container {
    height: 17px;
    width: 116px;
    //background: $primaryColor;
    background: linear-gradient(to bottom, rgba(125,125,125,1) 0%,rgba(34,34,34,1) 100%);
    padding: 5px;
    border-radius: 5px;
    clear: both;
    .play, .pause {
      width: 14px;
      height: 16px;
      display: inline-block;
      overflow: hidden;
      text-indent: -9999px;
      transition-property: background;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      cursor: pointer;
      float: left;
    }
    .play {
      background-image: url("/images/simple/black/play.png");
      &:hover {
        background: url("/images/simple/black/play_hover.png");
      }

      &:active {
        background: url("/images/simple/black/play_active.png");
      }
    }
    .pause {
      background: url("/images/simple/black/pause.png");
      &:hover {
        background: url("/images/simple/black/pause_hover.png");
      }

      &:active {
        background: url("/images/simple/black/pause_active.png");
      }
    }
    .mutecontrol {
      position: absolute;
      top: 8px;
      left: 26px;
      width: 7px;
      height: 11px;
      cursor: pointer;
      background: url("/images/simple/black/muteactive.png");
      &.active {
        background: url("/images/simple/black/sound.png");
      }
    }
    .volume {
      //margin-top: 10px;
      width: 80px;
      position: absolute;
      right:5px;
      top: 10px;
    }
    .noUi-horizontal {
      height: 6px;
      background: $primaryColor;
      box-shadow: none;
    }
    .noUi-connect {
      box-shadow: none;
      background: $secondColor;
    }
    .noUi-horizontal .noUi-handle {
      width: 8px;
      height: 8px;
      left: -3px;
      top: -2px;
      cursor: pointer;
      border-radius: 50%;
      &:before, &:after {
        content: none;
      }
    }
    .noUi-target {
      border: 1px solid $secondColor;
    }
  }
}