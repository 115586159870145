.panel-white {
  $player-background: #ededed;
  $player-border: #d9d9d9;
  $border-radius: 5px;
  $control-background: #757575;
  $control-color: #ffffff;
  $song-color: #000000;
  $volume-background: #dddddd;
  $volume-value: #838383;
  border:1px solid $player-border;
  bottom:0px;
  left:0px;
  width: 100%;
  position: fixed !important;
  background: $player-background;
  .player-wrapper {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    max-width:960px;
    margin:0 auto;
    align-items: center;
  }
  .logo {
    width: 60px;
    min-width:60px;
    height: 60px;
    border:1px solid $player-border;
    border-radius: $border-radius;
    background-size: cover;
  }
  .controls {
    margin-right: 15px;
  }
  .play, .pause {
    font-size: 14px;
    background: $control-background;
    color: $control-color;
    display: inline-block;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
    .fa {
      line-height: 35px;
    }
  }
  .current-song {
    font-size: 24px;
    color: $song-color;
    width: 500px;
  }
  .clear {
    clear: both;
  }
  .sound-control {
    margin-top:10px;
    width: 150px;
    font-size: 16px;
  }
  .noUi-horizontal {
    height: 8px;
    background: $volume-background;
    box-shadow: none;
  }
  .noUi-connect {
    box-shadow: none;
    background: $volume-value;
    height: 8px;
    top: -1px;
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
  }
  .noUi-horizontal .noUi-handle {
    width: 15px;
    height: 15px;
    left: -4px;
    top: -4px;
    cursor: pointer;
    border-radius: 50%;
    &:before, &:after {
      content: none;
    }
  }
  .mute-control {
    display: inline-block;
    width: 25px;
  }
  .mutecontrol {
    color: $volume-value;
    cursor: pointer;
    &.active {
      &:after {
        content: "\f028";
      }
    }
    &:after {
      content: "\f026";
      font-family: FontAwesome;
      font-size: 14px;
    }
  }
  .volume-control {
    display: inline-block;
    width: calc(100% - 30px) !important;
  }
  @media only screen and (max-width: 760px) {
    .sound-control {
      display: none;
    }
    .logo {
      margin-right: 10px;
    }
  }
}