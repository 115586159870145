.playmonster-black {
  .play-pause-container {
    width: 100px;
    text-align: center;
    .play, .pause {
      width: 83px;
      height: 94px;
      display: inline-block;
      overflow: hidden;
      text-indent: -9999px;
      transition-property: background;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      cursor: pointer;
    }
    .play {
      background-image: url("/images/playmonster/black/play.png");
      &:hover {
        background: url("/images/playmonster/black/play_hover.png");
      }

      &:active {
        background: url("/images/playmonster/black/play_active.png");
      }
    }
    .pause {
      background: url("/images/playmonster/black/pause.png");
      &:hover {
        background: url("/images/playmonster/black/pause_hover.png");
      }

      &:active {
        background: url("/images/playmonster/black/pause_active.png");
      }
    }
    .volume {
      margin-top: 10px;
    }
    .noUi-horizontal {
      height: 8px;
      background: #595959;
      box-shadow: none;
    }
    .noUi-connect {
      box-shadow: none;
      background: #a4a4a4;
    }
    .noUi-horizontal .noUi-handle {
      width: 15px;
      height: 15px;
      left: -4px;
      top: -4px;
      border-radius: 50%;
      cursor: pointer;
      &:before, &:after {
        content: none;
      }
    }
  }
}