@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&subset=cyrillic');
@import "playmonster/black";
@import "playmonster/white";
@import "infra/blue";
@import "infra/green";
@import "infra/orange";
@import "infra/red";
@import "infra/violet";
@import "simple/black";
@import "simple/white";
@import "extra/chrome";
@import "extra/elegant";
@import "extra/garbage";
@import "extra/sky";
@import "extra/summer";
@import "brick/white";
@import "brick/black";
@import "foursquare/black";
@import "foursquare/white";
@import "panel/white";
@import "panel/black";
@import "slider";
@import "marquee";
$fa-font-path:"/fonts";
@import "../../node_modules/font-awesome/scss/font-awesome";
#radioheart-player {
  position: relative;
  font-family: 'Open Sans Condensed', sans-serif;
  text-align: left;
  z-index: 1000;
}
.play-pause-container {
  position: relative;
}
.hidden {
  display: none !important;
  transition: 0.5s ease;
}
.align-item-center {
  align-items: center !important;
}