.foursquare-white {
  $player-background: #ffffff;
  $player-border: #d9d9d9;
  $border-radius: 5px;
  $control-background: #2b97e3;
  $control-color: #ffffff;
  $song-color: #000000;
  $volume-background: #dddddd;
  $volume-value: #838383;
  .player-wrapper {
    padding: 10px;
    background: $player-background;
    border:1px solid $player-border;
    border-radius: $border-radius;
    position: relative;
    display: inline-block;
  }
  .logo {
    width: 100px;
    height: 100px;
    background-size: cover;
    position: absolute;
    border-radius: 5px;
  }
  .player-elements {
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .controls {
    text-align: center;
  }
  .play, .pause {
    font-size: 14px;
    background: $control-background;
    color: $control-color;
    display: inline-block;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
    .fa {
      line-height: 35px;
    }
  }
  .current-song {
    display: none;
  }
  .clear {
    clear: both;
  }
  .sound-control {
    margin-top:-30px;
    width: 100%;
  }
  .noUi-horizontal {
    height: 8px;
    background: $volume-background;
    box-shadow: none;
  }
  .noUi-connect {
    box-shadow: none;
    background: $volume-value;
    height: 8px;
    top: -1px;
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
  }
  .noUi-horizontal .noUi-handle {
    width: 15px;
    height: 15px;
    left: -4px;
    top: -4px;
    cursor: pointer;
    border-radius: 50%;
    &:before, &:after {
      content: none;
    }
  }
  .mute-control {
    display: inline-block;
    width: 25px;
  }
  .mutecontrol {
    color: $volume-value;
    cursor: pointer;
    &.active {
      &:after {
        content: "\f028";
      }
    }
    &:after {
      content: "\f026";
      font-family: FontAwesome;
      font-size: 14px;
    }
  }
  .volume-control {
    display: inline-block;
    width: 100%;
  }
}