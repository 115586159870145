$primaryColor: #f9c9f0;
$secondColor: #E473CE;
.infra-violet {
  .play-pause-container {
    width: 50px;
    text-align: center;
    .play, .pause {
      width: 47px;
      height: 58px;
      display: inline-block;
      overflow: hidden;
      text-indent: -9999px;
      transition-property: background;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      cursor: pointer;
    }
    .play {
      background-image: url("/images/infra/violet/play.png");
      &:hover {
        background: url("/images/infra/violet/play_hover.png");
      }

      &:active {
        background: url("/images/infra/violet/play_active.png");
      }
    }
    .pause {
      background: url("/images/infra/violet/pause.png");
      &:hover {
        background: url("/images/infra/violet/pause_hover.png");
      }

      &:active {
        background: url("/images/infra/violet/pause_active.png");
      }
    }
    .volume {
      margin-top: 10px;
    }
    .noUi-horizontal {
      height: 6px;
      background: $primaryColor;
      box-shadow: none;
    }
    .noUi-connect {
      box-shadow: none;
      background: $secondColor;
    }
    .noUi-horizontal .noUi-handle {
      width: 8px;
      height: 8px;
      left: -3px;
      top: -2px;
      cursor: pointer;
      border-radius: 50%;
      &:before, &:after {
        content: none;
      }
    }
    .noUi-target {
      border: 1px solid $secondColor;
    }
  }
}